import Map from './modules/map.js'
class IndexView {
    constructor() {
        this.selectors = {
            document: document,
            map           : '#js-map',
            shareMapPopup : '#map-share-button',
        };

        this.init();
        this.bind();
    };

    init() {
        this.getNodes();
        this.initMap();

    }

    getNodes() {
        var _this = this;

        this.nodes = {

        };

        $.each(this.selectors, function(key, selector) {
            _this.nodes[key] = $(selector);
        });
    }

    initMap() {
        if(this.nodes.map.length == 0) {
            return;
        }
        new Map({
            mapid: this.selectors.map.replace('#', '')
        });
    }

    bind() {
        var _this = this;

    };

}

new IndexView()

class MapModule {

    constructor(options) {
        var defaults = {
            nodes: {},
            mapid: 'js-map',
            leaflet: {
                scrollWheelZoom: false,
                zoomsliderControl: true,
                zoom: 12,
                center: {
                    lat: 47.01475,
                    lng: 28.8219
                },
                maxBounds: '',
                zoomControl: true,
                //layers: L.tileLayer('https://i.simpalsmedia.com/map/1/{z}/{x}/{y}.png'),
                physic:{
                    subdomains : ['mt0','mt1','mt2','mt3'],
                    attribution: 'physic'
                }
            },
            paths: {
                point: ' ',
                google: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
                street: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            },
            layers:{
                lealfetRightControls: '.leaflet-control-layers-expanded',
                group:{
                    objectsLayer: new (L.FeatureGroup),
                    cluster: new L.MarkerClusterGroup({showCoverageOnHover:false,removeOutsideVisibleBounds:true,maxClusterRadius:30}),
                },
                base:{
                    schema: null,
                    overlay: null,
                    physic: null,
                    street: null
                },
                options:{
                    schema:{
                        maxZoom    : 19,
                        minZoom    : 8,
                        tileType   : 1,
                        attribution: 'schema'
                    },
                    overlay:{
                        maxZoom    : 19,
                        minZoom    : 8,
                        tileType   : 2,
                        attribution: 'overlay'
                    },
                    physic:{
                        maxZoom    : 19,
                        minZoom    : 8,
                        subdomains : ['mt0','mt1','mt2','mt3'],
                        attribution: 'physic'
                    },
                    street:{
                        maxZoom    : 19,
                        minZoom    : 8,
                        subdomains : ['mt0','mt1','mt2','mt3'],
                        attribution: 'schema'
                    }
                }
            }
        };

        this.options = $.extend(true, defaults, options);
        this.url = $('#' + this.options.mapid)

        this.selectors = {
            map: this.options.selector
        }

        this.products = [];
        this.markers = '';
        this.elements = {
            document,
            treeFilter: '.js-tree-type',
            typeFilter: '.js-type',
            treeFilterAvailable: '.js-filter-available',
            treeFilterNotAvailable: '.js-filter-not-available',
            mapContainer: '#main-map',
            filterAvailable: '.js-available',
        };

        this.minPrice = 0;
        this.maxPrice = 0;
        this.data = [];

        this.hasPriceFilter = $('#price-range').length == 1
        this.initNodes();
        this.initMap();
        this.bindShare();
        this.layerSwitch();
        this.events();
        this.initZoomControl();
        this.initTooltipster();
        this.events();
        this.bind();

    }
    initNodes() {
        this.getNodes();
    }


    getNodes() {
        this.nodes = {};

        $.each(this.elements, (key, selector) => {
            this.nodes[key] = $(selector);
        });
    }
    events() {

        var _this = this;

        _this.map.on('zoomstart', function(e) {
            $('path.leaflet-interactive').hide();
        })

        _this.map.on('zoomend', function(e) {
            $('path.leaflet-interactive').show();
        })

        _this.map.on('popupopen', function(e) {
            $('.leaflet-popup-pane').find('.magnific-popup').magnificPopup({
                type: 'image'
            });
        });
    }

    bind() {

        var _this = this;
        _this.nodes.document.on('change', _this.elements.treeFilter, function(e){
            var markers = new L.MarkerClusterGroup();
            _this.options.layers.group.cluster.clearLayers();
            var type = _this.getFilterType();
            _this.products.forEach(function(data){
                if ($.inArray(data.point.type, type) !== -1) {
                    var marker = _this.createMarker([data.loc[0], data.loc[1]],data.point);
                    _this.options.layers.group.cluster.addLayer(marker);
                }
            });
            _this.map.addLayer(_this.options.layers.group.cluster);
        });

        _this.nodes.document.on('change', _this.elements.filterAvailable, function(e){
            const $this = $(e.currentTarget);
            var markers = new L.MarkerClusterGroup();
            _this.options.layers.group.cluster.clearLayers();
            var available = _this.getFilterAvailable();
            if (available.length) {
                _this.products.forEach(function(data){
                    if ($.inArray(data.point.is_available, available) !== -1) {
                        var marker = _this.createMarker([data.loc[0], data.loc[1]], data.point);
                        _this.options.layers.group.cluster.addLayer(marker);
                    }
                });
            }
            _this.map.addLayer(_this.options.layers.group.cluster);
        });
    }

    initMap() {
        var _this = this;
        this.options.layers.base.schema = L.tileLayer(this.options.paths.point, this.options.layers.options.schema)
        this.options.layers.base.physic = L.tileLayer(this.options.paths.google, this.options.layers.options.physic)
        this.options.layers.base.street = L.tileLayer(this.options.paths.street, this.options.layers.options.physic)
        this.options.layers.base.overlay = L.tileLayer(this.options.paths.point, this.options.layers.options.overlay)
        var southWest = L.latLng(45.00, 23.00),
            northEast = L.latLng(49.00, 34.00);

        this.options.leaflet.maxBounds = L.latLngBounds(southWest, northEast);

        this.map = new L.map(this.options.mapid, this.options.leaflet);
        this.createPointLayer();

        this.map.attributionControl.setPrefix('');

        this.options.layers.group.objectsLayer.addTo(this.map)

        this.map.on('moveend', function() {
            MAP_CONFIG.url.lat =_this.map.getCenter().lat;
            MAP_CONFIG.url.lng = _this.map.getCenter().lng;
            MAP_CONFIG.url.zoom = _this.map.getZoom();
            //_this.getCurrentUrl()
        });


        this.getProducts();

    }

    layerSwitch() {
        var _this = this;

        $(_this.options.layers.lealfetRightControls).children('form').css('visibility','hidden')
        $(_this.options.layers.lealfetRightControls).addClass('two-items-layer-switcher')
        $(_this.options.layers.lealfetRightControls).addClass('point')
        $(_this.options.layers.lealfetRightControls).on('click', function(e){
            $(_this.options.layers.lealfetRightControls).children('form').find('input:not(:checked)').attr('checked',true).trigger('click')
            $(_this.options.layers.lealfetRightControls).toggleClass('google')
        });
        $(_this.options.layers.lealfetRightControls).children('form').find('input').on('click', function(e){
            e.stopPropagation();
        })

    }

    initZoomControl() {
        if(!this.options.leaflet.zoomControl) {
            var options = {
                position: 'topleft'
            }

            options = $.extend(this, options, CONFIG.translations.leaflet.zoomControl);

            var control = new L.Control.Zoom(options).addTo(this.map)
        }
    }

    initTooltipster() {
        $('.leaflet-control-zoom a, .leaflet-draw a').tooltipster({
            position: 'right'
        });
    }

    createPointLayer() {
        var pointLayer = L.tileLayer('//i.simpalsmedia.com/map/1/{z}/{x}/{y}.png', {
            maxZoom: 18,
            minZoom: 8
        })
        //
        //var googleLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', this.options.leaflet.physic);

        this.map.addControl(new L.Control.Layers( {'Point':this.options.layers.base.schema, 'Google':this.options.layers.base.physic},{},{collapsed:false}));
        this.pointLayer = pointLayer.addTo(this.map);
    }

    createStreetLayer() {
        var pointLayer = L.tileLayer(this.options.paths.street, this.options.layers.options.physic)

        this.map.addControl(new L.Control.Layers( {'Street':this.options.layers.base.street, 'Google':this.options.layers.base.physic},{},{collapsed:false}));
        this.pointLayer = pointLayer.addTo(this.map);
    }

    getFilterType(){
        return this.nodes.treeFilter.filter(':checked').map(function(){
            return parseInt(this.value);
        }).get()
    }
    getFilterAvailable(){
        var available = [];
        this.nodes.filterAvailable.filter(':checked').each(function(){
            available.push(JSON.parse(this.value));
        });
        return available;
    }



    bindShare(){

        var _this = this
        var shareButton = $('#map-share-button');
        if( shareButton.length ){
            shareButton.magnificPopup({
                type:'inline',
                callbacks:{
                    open: function(){
                        $('#map-share-link').val(window.location.protocol + '//' + window.location.hostname + window.location.pathname + '?zoom=' + _this.map.getZoom() + '&lat=' + _this.map.getCenter().lat  + '&lng=' + _this.map.getCenter().lng + '#main-map' );
                    },
                }
            });
        }

    }

    bindPriceFilter(){

        var _this = this;

        if(this.hasPriceFilter){

            $('#price-range').val(this.minPrice + ',' + this.maxPrice);

            $('#price-range').off('change');

            $('#price-range').jRange({
                from: this.minPrice,
                to: this.maxPrice,
                step: 1,
                format: '%s',
                width: 300,
                showLabels: true,
                isRange : true,
                ondragend: function(range){
                    var min = range.split(',')[0];
                    var max = range.split(',')[1];

                    var markers = new L.MarkerClusterGroup();
                    _this.options.layers.group.cluster.clearLayers();
                    _this.products.forEach(function(data){
                        if(data.point.price <= max && data.point.price >= min){
                            var marker = _this.createMarker([data.loc[0], data.loc[1]],data.point);
                            _this.options.layers.group.cluster.addLayer(marker);
                        }
                    });
                    //marker = L.marker([point.lat_lon.lat, point.lat_lon.lon],point)

                    _this.map.addLayer(_this.options.layers.group.cluster);

                }
            });


        }
    }

    getProducts() {

        var _this = this

        var markers = new L.MarkerClusterGroup();
        _this.options.layers.group.cluster.clearLayers();
        var type = $('#' + _this.options.mapid).data('type');
        var url = $('#' + _this.options.mapid).data('link');
        this.minPrice = 0
        this.maxPrice = 0

        _this.nodes.mapContainer.addClass('loading');
        $.ajax(url, {
            type: 'get',
            success: function(data){
                if (data){
                    var cord, point, bounds, marker;
                    bounds = [];

                    $.each(data.products, function( index, item ) {
                        const not_available = item.features['20386'] ? item.features['20386']['value'] : false;
                        const unit = item.features['44'] ? item.features['44']['unit'] : '--';
                        const price = item.features['44'] ? item.features['44']['value'] : '--';

                        if (1) {

                            cord = item.features['71'];

                            if(_this.hasPriceFilter){
                                if( price > _this.maxPrice )
                                    _this.maxPrice = price

                                if( price < _this.minPrice || _this.minPrice == 0 )
                                    _this.minPrice = price

                            }

                            point = {
                                is_available: !not_available,
                                name_ro: item.title_ro,
                                name_en: item.title_en,
                                name_ru: item.title,
                                slug: item._id,
                                main_image: item.features['14']['value'][0],
                                images: item.features['14']['value'],
                                unit: unit,
                                price: price,
                                type: item.features['20001'] ? item.features['20001']['value'] : '',
                                location_name_ro: item.features['19219'] ? item.features['19219']['obj']['value'][0]['title']['ro'] : '',
                                location_name_ru: item.features['19219'] ? item.features['19219']['obj']['value'][0]['title']['ru'] : '',
                                region_ro: item.features['20022'] ? item.features['20022']['obj']['value'][0]['title']['ro'] : '',
                                region_ru: item.features['20022'] ? item.features['20022']['obj']['value'][0]['title']['ru'] : '',
                                region_en: item.features['20022'] ? item.features['20022']['obj']['value'][0]['title']['en'] : '',
                                status_ro: item.features['20406'] ? item.features['20406']['value']['ro'] : '',
                                status_ru: item.features['20406'] ? item.features['20406']['value']['ru'] : '',
                                status_en: item.features['20406'] ? item.features['20406']['value']['en'] : '',
                                street: item.features['20552'] ? item.features['20552']['value'] : '',
                            };
                            marker = _this.createMarker([cord.value.lat, cord.value.lng],point)
                            //marker = L.marker([point.lat_lon.lat, point.lat_lon.lon],point)
                            _this.options.layers.group.cluster.addLayer(marker)

                            _this.products.push({
                                point: point,
                                loc: [cord.value.lat, cord.value.lng]
                            });


                            bounds.push([cord.value.lat, cord.value.lng]);
                        }
                    });
                    _this.map.addLayer(_this.options.layers.group.cluster);
                    if(_this.hasPriceFilter){
                        _this.bindPriceFilter();
                    }
                }
                _this.nodes.mapContainer.removeClass('loading');
            }
        });

    }

    createMarker(latLon, point) {

        var customPopup = '',
            title,
            location,
            region,
            marker_ico,
            status,
            iconUrl = '/static/projects/ooh.md/dist/images/marker.png';

        title = {ro: point.name_ro, ru: point.name_ru, en: point.name_en};
        location = {ro: point.location_name_ro, ru: point.location_name_ru};
        region = {ro: point.region_ro, ru: point.region_ru, en: point.region_en};
        status = {ro: point.status_ro, ru: point.status_ru, en: point.status_en};
        // street = {ro: point.street_name_ro, ru: point.street_name_ru};

        // location[MAP_CONFIG.lang] += ', ' + street[MAP_CONFIG.lang]

        if (point.house_number) {
            location[MAP_CONFIG.lang] += ', ' + point.house_number
        }

        var html = $('#js-template-popup-buble').html();
        Mustache.parse(html);
        var popupHtml = Mustache.render(html, {
            is_available: point.is_available,
            url: point.slug,
            image: point.main_image,
            images: point.images,
            // client_name: point.client_name,
            title:title[MAP_CONFIG.lang],
            address: location[MAP_CONFIG.lang],
            price: point.price + ' ' + point.unit,
            region: region[MAP_CONFIG.lang],
            status: status[MAP_CONFIG.lang],
            street: point.street,
            // year: point.min_completion_year.year,
            // quarter: point.min_completion_year.quarter
        });
        if (!point.is_available) {
            iconUrl = '/static/projects/ooh.md/dist/images/marker.png';
        }
        marker_ico = L.icon({
            iconUrl: iconUrl,
            iconSize: [21,43],
            iconAnchor: [22,45],
            popupAnchor: [100,-36]
        })
        var test = point.images

        point = L.marker(latLon, {
            icon: marker_ico,
            title:title[MAP_CONFIG.lang]
        }).on('click', function (e) {

            $('#js-filters-opener').trigger('close.filter');

            document.getElementById("popup-map").innerHTML = popupHtml;

            $(document.getElementById("js-map-slider")).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 300,
                infinite: false,
                prevArrow: '<span class="slick-prev map-controls__view-object-controls map-controls__view-object-prev"><span class="fa fa-chevron-left"></span></span>',
                nextArrow: '<span class="slick-next map-controls__view-object-controls map-controls__view-object-next"><span class="fa fa-chevron-right"></span></span>'
            })

        });

        return point;
    }
}

export default MapModule;
